<template>
  <div class="w-100">
    <fb-signin-button :params="fbSignInParams" @success="onSignInSuccessFacebook" @error="onSignInErrorFacebook">
      <b-button class="btn btn-login btn-facebook">{{ $t('login_fa') }}</b-button>
    </fb-signin-button>
    <GoogleLogin :params="params" :onSuccess="onSuccessGoogle" class="btn-login btn-google btn btn-block" :onFailure="onFailureGoogle">{{ $t('login_go') }}</GoogleLogin>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import GoogleLogin from 'vue-google-login'

export default {
  components: {
    GoogleLogin
  },
  data () {
    return {
      login: {},
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      params: {
          client_id: '431572037458-vajc1nskddjl6cu1caqj536plgjk8rc2.apps.googleusercontent.com'
      },
      renderParams: {
          width: 250,
          height: 50,
          longtitle: true
      }
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console'
    ])
  },
  methods: {
    onSuccessGoogle (googleUser) {
      const path = `${this.lang}/login-google`
      const token = googleUser.getAuthResponse().id_token;
      const payload = {
        token
      }
      this.$axios.post(path, payload).then((response) => {
        const data = response.data
        this.setUserAfterLogin(data, data.user.lang, this.console)
      })
    },
    onFailureGoogle (error) {
      console.log('Error al iniciar sesión con google:', error)
    },
    onSignInSuccessFacebook (response) {
      const path = `${this.lang}/login-facebook`
      const payload = {
        token: FB.getAuthResponse()['accessToken']
      }
      this.$axios.post(path, payload).then((result) => {
        const data = result.data
        this.setUserAfterLogin(data, data.user.lang, this.console)
      })
    },
    onSignInErrorFacebook (error) {
      console.log('Ocurrió un error:', error)
    }
  }
}
</script>
